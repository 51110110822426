<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ page_name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--内容-->
    <div v-if="isextend" class="page-content-x">
      <!--搜索-->
      <div v-show="issearch" class="page-content-search">
        <el-form
          ref="form"
          :model="search"
          size="medium"
          :label-width="this.env.search_label_width"
        >
          <el-row>
            <el-col :span="6">
              <el-form-item label="拼团名称">
                <el-input v-model="search.groupbuy_name" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="产品名称">
                <el-input v-model="search.product_title" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="产品线">
                <el-select
                  v-model="search.product"
                  placeholder="请选择产品线"
                  style="width: 100%"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option label="团课" value="group"></el-option>
                  <el-option label="训练营" value="camp"></el-option>
                  <el-option label="活动" value="activity"></el-option>
                  <el-option label="商品" value="commodity"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="page-content-search-button">
            <el-col :span="24">
              <el-form-item>
                <el-button
                  icon="el-icon-search"
                  size="medium"
                  type="primary"
                  @click="is_search"
                  >搜索
                </el-button>
                <el-button icon="el-icon-circle-close" size="medium" @click="init"
                  >清空搜索
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!--标题-->
      <el-row>
        <el-col :span="12"
          ><h3>{{ page_name }}</h3></el-col
        >
        <el-col :span="12">
          <div class="top-operation-button">
            <el-button
              v-if="issearch"
              icon="el-icon-caret-top"
              size="medium"
              type="primary"
              @click="issearch = !issearch"
              >隐藏搜索
            </el-button>
            <el-button
              v-else
              icon="el-icon-caret-bottom"
              size="medium"
              type="primary"
              @click="issearch = !issearch"
              >显示搜索
            </el-button>
            <el-button
              v-if="is_auth('marketing.groupbuy.issave')"
              icon="el-icon-plus"
              size="medium"
              type="primary"
              @click="tosee()"
              >添加拼团
            </el-button>
          </div>
        </el-col>
      </el-row>
      <div style="height: 20px"></div>
      <!--列表-->
      <el-table
        size="medium"
        border
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column prop="groupbuy_name" label="拼团活动名称" width="120" show-overflow-tooltip />
        <el-table-column prop="state" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.state === 1" size="mini">正常</el-tag>
            <el-tag v-else-if="scope.row.state === 2" type="warning" size="mini"
              >禁用</el-tag
            >
            <el-tag v-else type="info" size="mini">正常</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="product" label="产品线">
          <template slot-scope="scope">
            <span v-if="scope.row.product === 'group'">团课</span>
            <span v-else-if="scope.row.product === 'camp'">训练营</span>
            <span v-else-if="scope.row.product === 'activity'">活动</span>
            <span v-else-if="scope.row.product === 'commodity'">商品</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="product_title" label="产品名称" width="120" show-overflow-tooltip />
        <el-table-column prop="groupbuy_name" label="课程日期" width="60">
          <template slot-scope="scope">
            <el-popover
              v-if="scope.row.timedata"
              placement="right"
              width="200"
              trigger="hover"
            >
              <el-table :data="scope.row.timedata">
                <el-table-column label="上课时间">
                  <template slot-scope="scope">
                    <span>{{ scope.row }}</span>
                  </template>
                </el-table-column>
              </el-table>
              <el-link
                style="width: 100%; text-align: center"
                slot="reference"
                type="primary"
              >
                查看
              </el-link>
            </el-popover>
            <p v-else style="width: 100%; text-align: center">/</p>
          </template>
        </el-table-column>
        <el-table-column prop="min_price" label="最低价格" />
        <el-table-column prop="complete_sum" label="拼团成功数量" />
        <el-table-column prop="initiate_sum" label="发起数量" />
        <el-table-column prop="create_time" label="创建时间" width="120" show-overflow-tooltip />
        <el-table-column prop="update_time" label="更新时间" width="120" show-overflow-tooltip />
        <el-table-column fixed="right" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              v-if="is_auth('marketing.groupbuy.issave')"
              @click="tosee(scope.row.groupbuy_uuid)"
              size="mini"
              >编辑
            </el-button>
            <el-button
              v-if="is_auth('marketing.groupbuy.getinitiatelist')"
              @click="to_initiatelist(scope.row.groupbuy_uuid)"
              type="primary"
              size="mini"
              >查看拼团
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 20px"></div>
      <!--分页-->
      <el-pagination
        @current-change="getlist"
        :page-size="this.env.pageSize"
        :pager-count="7"
        background
        layout="prev, pager, next, total"
        :current-page.sync="page"
        :total="count"
      >
      </el-pagination>
    </div>
    <div v-else class="page-content-x">
      <div class="open-info">
        <div class="open-info-img">
          <img
            src="https://img.gymmartsports.com/gymmartsports/materialimg/21b2d202109061840289545.png?imageslim"
          />
        </div>
        <p>点击免费开通拼团活动</p>
      </div>
      <div class="open-btn">
        <el-button
          v-if="is_auth('marketing.groupbuy.enable')"
          type="primary"
          @click="
            () => {
              this.isextend_agreement = true;
            }
          "
          >开通拼团活动
        </el-button>
      </div>

      <!--拼团活动协议-->
      <el-dialog
        title="拼团活动协议"
        :visible.sync="isextend_agreement"
        width="80%"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      >
        <section class="agree-view">
          <div v-html="agreement.control" />
        </section>
        <div class="agree-btn">
          <el-button
            v-if="is_auth('marketing.groupbuy.enable')"
            type="primary"
            @click="enable"
            >同意并开通拼团活动
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page_name: "拼团活动",
      agreement: [], // 开通协议
      isextend_agreement: false,
      isextend: true,

      loading: true, // 加载状态
      issearch: true, // 搜索是否展示
      search: {}, // 搜索内容
      count: 0, // 数据总条数
      page: 1, // 当前页数
      tableData: [], // 列表内容
    };
  },
  // 创建
  created() {
    this.get_extend();
    // this.init()
  },
  // 安装
  mounted() {},
  methods: {
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 初始化
    init() {
      this.search = {
        time_type: "start_time",
      };
      this.searchtime = null;
      this.is_search();
    },
    // 获取扩展权限
    get_extend() {
      let postdata = {
        api_name: "marketing.groupbuy.isextend",
        token: this.Tool.get_l_cache("token"),
      };
      this.loading = true;
      this.Tool.post_data("merchant", postdata, (json) => {
        console.log("json", json.agreement);
        // this.loading = false
        if (json.code === 0) {
          this.isextend = true;
          this.init();
        } else {
          this.isextend = false;
          this.agreement = json.agreement;
        }
      });
    },
    // 消费卡扩展开通
    enable() {
      let postdata = {
        api_name: "marketing.groupbuy.enable",
        token: this.Tool.get_l_cache("token"),
      };
      this.loading = true;
      this.Tool.post_data("merchant", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              location.reload();
            },
          });
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 获取列表
    getlist() {
      let postdata = {
        api_name: "marketing.groupbuy.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("merchant", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
          this.Tool.errormes(json);
        }
      });
    },
    // 拼团编辑
    tosee(groupbuy_uuid = null) {
      if (groupbuy_uuid === null) {
        this.$router.push({ path: "/groupbuy/list/edit" });
      } else {
        this.$router.push({ path: "/groupbuy/list/edit", query: { groupbuy_uuid } });
      }
    },
    // 查到拼团信息
    to_initiatelist(groupbuy_uuid) {
      this.$router.push({ path: "/groupbuy/initiatelist", query: { groupbuy_uuid } });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*表格不换行*/
.el-table__body .cell {
  white-space: nowrap;
  /*overflow: initial;*/
}

.el-table .cell {
  display: flex;
}

.form-tag_colour-div {
  min-height: 200px;
}

.open-info {
  width: 100%;
  text-align: center;
}

.open-info-img {
  width: 200px;
  margin: 50px auto;
}

.open-info-img img {
  width: 100%;
}

.open-info p {
  color: #999;
  margin-top: 60px;
  font-size: 14px;
}

.open-btn {
  margin-top: 20vh;
  text-align: center;
  margin-bottom: 10vh;
}

.agree-view {
  width: 100%;
  height: 60vh;
  overflow-y: scroll;
}

.agree-btn {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
</style>
